<template>
    <div class="view pa24 researchCosts" v-loading="loading">
        <div class="selectCompany flex-a-c hover_pointer" @click="showCompanyList"><i
                class="el-icon-office-building fs12 mr5"></i> <span class="fs13">{{ checkedCompany.companyName }}</span>
            <i class="el-icon-caret-bottom  fs12 ml5"></i>
        </div>
        <el-tabs v-model="searchData.year" @tab-click="searchFun" class="tabsWidthAuto">
            <el-tab-pane :label="String(y) + '年'" :name="String(y)" v-for="(y, index) in yearData"
                :key="index"></el-tab-pane>
        </el-tabs>
        <div v-if="projectId">
            <el-tabs v-model="projectId" @tab-click="switchType" class="tabsWidthAuto">
                <el-tab-pane :label="item.title" :name="String(item.pdId)" v-for="(item, id) in projectTab"
                    :key="id"></el-tab-pane>
            </el-tabs>
            <el-tabs v-model="searchData.modelType" @tab-click="switchType" class="tabsWidthAuto">
                <el-tab-pane label="分配说明" :name="String(9)"></el-tab-pane>
                <el-tab-pane label="人员名单" :name="String(11)"></el-tab-pane>
            </el-tabs>
            <div class="d-flex mt20 mb20">
                <div style="margin-left: auto;" v-if="checkedCompany.pcId">
                    <el-button type="primary" class="wordBtn" :disabled="!checkedCompany.pcId"
                        :loading="exportLoadingBtn" @click="submitFun(true)">WORD导出</el-button>
                    <el-button type="primary" :loading="addWordDataLoading" @click="submitFun()"
                        v-if="searchData.modelType == 9">保
                        存</el-button>
                    <el-button type="info" @click="delProjectData()" v-if="searchData.modelType == 9"
                        :disabled="!dataId" title="未保存项目数据，则为禁用状态"
                        :loading="delLoading">{{ dataId ? '恢复默认数据' : '当前为默认数据' }}</el-button>
                </div>
            </div>
            <div v-if="searchData.modelType == 9">
                <model9 ref="model" :projectData="projectData[projectId]" :modelData="wordProjectData">
                </model9>
            </div>
            <div v-if="searchData.modelType == 11">
                <model11 ref="model" :projectData="projectData[projectId]" :modelData="wordProjectData">
                </model11>
            </div>
        </div>
        <div v-else class="textc fs10 mt70 mb50">
            <h3>当前年份没有研发项目</h3>
        </div>
        <div>
            <h4></h4>
        </div>
        <select-company-list ref="selectCompanyList" @confirmFun="selectCompany"></select-company-list>
    </div>
</template>

<script>
import selectCompanyList from "@/pages/projectAdmin/components/selectCompanyList";
import { setProjectNormalWord, getNormalWordDataByPdId, getProjectDataList, delProjectNormalWord } from "@/api/projectAdmin";
import { getProjectMoneyDeduction } from "@/api/projectMoney";
import model9 from "./components/model9.vue";
import model11 from "./components/model11.vue";
export default {
    name: "personnelAllotment",
    components: {
        selectCompanyList,
        model9,
        model11
    },
    data() {
        return {
            loading: false, //人员分配数据加载
            yearData: [],//年的数据
            searchData: { year: "", modelType: "9" },
            projectData: {},//项目数据
            projectTab: [],
            projectId: "",//项目id
            checkedCompany: {},//选中的研发企业
            wordProjectData: null,//保存的人员分配数据
            exportLoadingBtn: false,
            addWordDataLoading: false,
            delLoading: false,
            dataId: 0,
        };
    },
    mounted() {
        let checkedCompany = JSON.parse(sessionStorage.getItem("checkedProjectCompany"));
        if (!checkedCompany) {
            this.showCompanyList();
        }
        this.checkedCompany = checkedCompany ? checkedCompany : { companyName: "请选择研发企业" };
        //获取年份
        this.getYearData();
        if (this.checkedCompany.pcId) {
            this.getProjectData().then(() => {
                this.getDetails();
            });
        }

    },
    methods: {
        /**@method 删除项目数据 */
        delProjectData() {
            let params = {
                dataId: this.dataId,
                modelType: this.searchData.modelType,
            };
            this.delLoading = true;
            delProjectNormalWord(params).then(res => {
                this.delLoading = false;
                if (res.code == 200) {
                    this.$message.success("删除旧数据完成，重新为你生成默认数据。");
                    this.getDetails();
                } else {
                    this.$message.error(res.message);
                }
            }).catch(err => {
                this.delLoading = false;
                if (err.code != 201) {
                    this.$message.error(err.message);
                }
            })
        },
        searchFun() {
            this.projectData = {};
            this.projectTab = [];
            this.wordProjectData = null;
            this.projectId = "";
            this.getProjectData().then(() => {
                this.getDetails();
            });
        },
        /**@method 显示选择研发企业 */
        showCompanyList() {
            this.$refs.selectCompanyList.showModelFun();
        },
        /**@method 选择研发企业 */
        selectCompany(row) {
            this.checkedCompany = row;
            sessionStorage.setItem("checkedProjectCompany", JSON.stringify(row));
            this.searchFun();
        },
        /**@method 获取最近五年 */
        getYearData() {
            let yearData = [];
            let F = new Date().getFullYear();
            for (let i = 0; i <= 4; i++) {
                yearData.push(F - i);
            }
            this.yearData = yearData;
            this.searchData.year = String(this.yearData[0])
        },
        switchType() {
            this.wordProjectData = null;
            this.getDetails();
        },
        /**@method 保存 */
        submitFun(isDownload) {
            if (!this.addWordDataLoading && !this.exportLoadingBtn) {
                if (isDownload) {
                    this.exportLoadingBtn = true;
                } else {
                    this.addWordDataLoading = true;
                }
                let content = {};
                for (let key in this.$refs.model.formData) {
                    if (typeof this.$refs.model.formData[key] == 'object') {
                        content[key] = JSON.stringify(this.$refs.model.formData[key])
                    } else {
                        content[key] = this.$refs.model.formData[key];
                    }
                }
                let params = {
                    pdId: this.projectId,
                    modelType: this.searchData.modelType,
                    content: content,
                    year: this.searchData.year
                };
                if (isDownload) {
                    params.isDownload = true;
                }
                setProjectNormalWord(params).then(res => {
                    if (isDownload) {
                        this.exportLoadingBtn = false;
                    } else {
                        this.addWordDataLoading = false;
                    }
                    if (res.code === 200) {
                        this.getDetails();
                        if (isDownload) {
                            // window.open(this.$store.state.apiUrl + '/crmPc/project/downloadWordFile?fileName=' + res.data);
                            ////-console.log(this.$store.state.apiUrl + '/crmPc/project/downloadWordFile?fileName=' + res.data);
                            let link = document.createElement('a');
                            link.href = this.$store.state.apiUrl + '/crmPc/project/downloadWordFile?fileName=' + res.data;
                            link.click();
                        }
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch(err => {
                   //-console.log(err);
                    if (isDownload) {
                        this.exportLoadingBtn = false;
                    } else {
                        this.addWordDataLoading = false;
                    }
                    if (err.code === 201) {
                        this.$message.error(err.message);
                    }
                })
            }
        },
        /**@method 获取项目数据列表 */
        getProjectData() {
            // let params = {
            //     pageSize: 50,
            //     pageNum: 1,
            //     selectYear: this.searchData.year,
            //     pcId: this.checkedCompany.pcId
            // };
            this.loading = true;
            return getProjectMoneyDeduction({ year: this.searchData.year, pcId: this.checkedCompany.pcId }).then(result => {
                if (result.data) {
                    result.data.splice(result.data.length - 1, 1);
                    this.projectId = String(result.data[0].pdId);
                    for (let row of result.data) {
                        if (row.pdId) {
                            row.title = row.code + row.name.slice(0, 5) + "...";
                            this.projectData[row.pdId] = row;
                            this.projectTab.push(row);
                        }
                    }
                } else {
                    this.loading = false;
                }
            }).catch(err => {
                this.loading = false;
            });
            // return getProjectDataList(params).then(result => {
            //     if (result.data) {
            //         this.projectId = String(result.data.pageInfo.list[result.data.pageInfo.list.length - 1].pdId);
            //         for (let row of result.data.pageInfo.list) {
            //             row.title = row.projectNo + row.projectName.slice(0, 5) + "...";
            //             this.projectData[row.pdId] = row;
            //         }
            //     }
            // }).catch(err => {
            //     this.loading = false;
            // });
        },
        /**@method 获取人员分配数据 */
        getDetails() {
            if (!this.projectId) {
                return;
            }
            this.loading = true;
            this.wordProjectData = null;
            this.dataId = 0;
            getNormalWordDataByPdId({
                pdId: this.projectId,
                year: this.searchData.year,
                modelType: this.searchData.modelType
            }).then(res => {
                this.loading = false;
                if (res.data) {
                    this.wordProjectData = res.data.wordData;
                    if (this.searchData.modelType == 9) {
                        this.dataId = res.data.wordData.id;
                    }
                } else {
                    if (this.searchData.modelType == 9) {
                        this.wordProjectData = {};
                    } else if (this.searchData.modelType == 11) {
                        this.wordProjectData = [];
                    }
                }
            }).catch(err => {
                this.loading = false;
                if (this.searchData.modelType == 9) {
                    this.wordProjectData = {};
                } else if (this.searchData.modelType == 11) {
                    this.wordProjectData = [];
                }
            })
        },
        /**@method 显示新增模态框 */
        showAddModel() {
            this.showModel = true;
        },
        /**@method 编辑
         * @param {Object} val - 当前点击行的值
         */
        showUpdateModel(val) {
            this.showModel = true;
        },
    },
};
</script>
<style lang="scss" scoped>
.selectCompany {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 700;
    color: #51CBCD;
    cursor: pointer;
}

.assistrecordTable {
    border-color: #000;
    word-break: break-all;
    // table-layout: fixed;

    thead tr,
    tbody tr,
    tfoot tr {
        // display: table;
        width: 100%;

        // table-layout: fixed;
        td {
            box-sizing: border-box;
        }
    }

    tbody {
        // display: block;
    }

    .itemTitle {
        font-size: 16px;
        padding: 5px 10px;
    }

    .itemContent {
        min-height: 30px;
        font-size: 16px;
        padding: 5px 10px;
    }

}
</style>